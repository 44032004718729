@tailwind base;
@tailwind components;
@tailwind utilities;



.dollar-sign-input {
  -moz-appearance: textfield;
  /* For Firefox */
  appearance: textfield;
  padding-left: 30px; /* Adjust padding as needed */
}

.dollar-sign-input::before {
  content: "$";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #333;
  font-size: 1rem; /* Adjust font size as needed */
}

.GYBbackgroundImage {
  background-image: url(/GYBLoginBackgroundImage.jpg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.my-green-table thead {
  background-color: green;
  color: white;
}

.Box {
   width: 50%;
  background: #fff;
  border-radius: 3rem;
}

.DashboardAdminTable td [class*="MuiTextField-root"] {
  min-width: 155px;
}

.DashboardAdminTable td input{
  font-size: 18px;
  font-weight: 700;
}

body, html {
  height: 100%;
}

.contentOverFlow {
  height: calc(100% - 64px); 
  overflow-y: auto; 
}



.swal2-styled.swal2-confirm {
  font-size: 17px;
  padding: 10px 60px;
}

.swal2-styled.swal2-deny {
  font-size: 17px;
  padding: 10px 60px;
  margin-right: 60px;
}

.swal2-title {
  font-size: 20px; /* Adjust title font size */
  font-weight: normal; /* Remove bold */
}


.sticky-top-content {
  position: sticky;
  top: 0;
  background-color: white; 
  z-index: 30; 
  padding-bottom: 10px;
  padding-top: 10px;
}

.sticky-table-header {
  position: sticky;
  top: calc(57px);
  z-index: 25;
}


.sticky-table-header-All-Transactions-Page {
  position: sticky;
  top: calc(65px);
  background-color: white;
  z-index: 25;
}


.sticky-table-header-Add-Accounts-page {
  position: sticky;
  top: calc(49px);
  z-index: 25;
}